/* ==================== */
/* COMMON CSS */
/* ==================== */

@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');

:root {
  /* Colors */
  --main-color: #5a7f92;
  --secondary-color: #414956;
  --black-color: #1B1B1B;
  --grey-color: #666666;
  --light-grey-color: #999999;
  --white-color: #fff;

  --body-bg: #F8FCFE;
  --main-color-hover: #7b949e;

  /* Fonts */
  --main-font: 'Alegreya Sans', sans-serif;
  --heading-font: 'Dancing Script', cursive;

  --section-gap: 120px;
}

html, body {
  font-family: var(--main-font);
  color: var(--grey-color);
  background-color: var(--body-bg);
  scroll-behavior: smooth;
}

img { max-width: 100%; }

@media (max-width: 1680px) {
  html, body{ --section-gap: 100px }
}
@media (max-width: 1366px) {
  html, body{ --section-gap: 80px }
}

/* Titles */
.section-title, 
.banner .banner-title { font-size: 70px; font-family: var(--heading-font); text-align: center; color: var(--main-color); }
.section-title-2{ font-size: 50px; font-family: var(--heading-font); color: var(--main-color); margin-bottom: 30px; } 
.banner .banner-title { position: relative; width: fit-content; margin-inline: auto; margin-bottom: 25px; }
.banner .banner-title::before { content: ''; position: absolute; width: 200px; height: 2px; background-color: var(--main-color); left: -20px; transform: translateX(-100%); top: 50% ; }
.section-title {margin-bottom: 50px;}

@media (max-width: 1680px) {
  .section-title, 
  .banner .banner-title { font-size: 64px; }
}
@media (max-width: 1440px) {
  .section-title, 
  .banner .banner-title { font-size: 52px; }
  .banner .banner-title { margin-bottom: 10px; }
  .section-title {margin-bottom: 30px;}
  .section-title-2 {font-size: 38px;margin-bottom: 20px;} 
}
@media (max-width: 1280px) {
  .section-title, 
  .banner .banner-title { font-size: 48px; }
  .section-title {margin-bottom: 20px;}
}
@media (max-width: 991px) {
  .banner .banner-title { width: 100%; text-align: start; }
  .banner .banner-title::before { content: none; }
}
@media (max-width: 600px) {
  .section-title, 
  .banner .banner-title { font-size: 32px; }
}
  
/* Button */
.header .main-button,
.mainBanner .main-button,
.banner .main-button,
.contact-us .main-button {width: fit-content; background-color: var(--main-color);border-radius: 100px;font-family: var(--main-font);font-size: 20px;line-height: 30px; padding: 12px 25px; text-transform: none; font-weight: 500; min-width: 200px; }
.header .main-button:hover,
.mainBanner .main-button:hover,
.banner .main-button:hover,
.contact-us .main-button:hover {background-color: var(--main-color-hover);}

@media (max-width: 1440px) {
  .header .main-button,
  .mainBanner .main-button,
  .banner .main-button,
  .contact-us .main-button {min-width: 150px;padding: 8px 25px;font-size: 18px;}
}
@media (max-width: 600px) {
  .header .main-button,
  .mainBanner .main-button,
  .banner .main-button,
  .contact-us .main-button {min-width: 100px;font-size: 16px;line-height: 24px;}
}


.mainBanner, .banner {
  animation: fadeIn 1s ease-in-out forwards;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}