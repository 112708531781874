.banner { margin: var(--section-gap) 0; min-height: 70vh; }
.banner .banner-img {margin: auto; display: flex;max-width: 100%;}
.banner .text-col { display: grid; align-content: center; height: 100%; }
.banner .lead { font-size: 30px; font-weight: 400; line-height: 38px; color: var(--black-color); margin-bottom: 20px; }
.banner p { font-size: 20px; line-height: 30px; margin-bottom: 20px; word-break: break-word; }
/* .banner button { padding: 10px 25px; width: fit-content; border-radius: 100px; background-color: var(--main-color); border-color: var(--main-color); font-family: var(--main-font); font-size: 20px; line-height: 30px; padding: 12px 45px; text-transform: none; } */
/* .banner button:hover {background-color: var(--main-color-hover);} */

.banner-1 img { height: 650px; width: 585px; border-radius: 10px; object-fit: cover; }

.banner-2{min-height: 65vh;}
.banner-2 .button-group{margin-top: 20px;display: flex;flex-wrap: wrap; gap: 15px;}
.banner-2 .button-group a{width: 180px;}


@media (max-width: 1680px) {
    .banner .lead { font-size: 28px; }
}
@media (max-width: 1440px) {
    .banner .lead { font-size: 25px; line-height: 32px; margin-bottom: 10px; }
}
@media (max-width: 1366px) {
    .banner-1 img { width: 545px; height: 600px; }
}
@media (max-width: 1200px) {
    .banner .lead { font-size: 22px; }
}
@media (max-width: 1100px) {
    .banner-1 img { width: 450px; height: 600px; }
}
@media (max-width: 991px) {
    .banner-1 img { height: auto; }
    .banner .banner-img {margin-bottom: 20px;}
}
@media (max-width: 600px) {
    .banner p {font-size: 18px; line-height: 28px; }
    .banner-2 .button-group a{width: 150px;}
}