.ourMission {display: flex;align-items: center;flex-wrap: wrap;min-height: 84vh;}
.ourMission .text-col{width: 60%;}
.ourMission .img-col{width: 40%;}
.ourMission .text-col{width: 60%;}
.ourMission .img-col{width: 40%;}
.ourMission .img-col img{width: 100%;}


.ourMission .text-col .text-wrapper {max-width: 650px;width: 90%;margin: auto;}
.ourMission .text-col .banner-title {font-size: 70px; font-weight: 500;; font-family: var(--heading-font); color: var(--main-color);margin-bottom: 20px;}
.ourMission .text-col .lead { font-size: 30px; font-weight: 400; line-height: 38px;color: var(--black-color); margin-bottom: 50px; }
.ourMission .text-col .heading { font-size: 34px; line-height: 38px; color: var(--black-color); font-weight: 500; margin-bottom: 10px; display: flex; gap: 20px; align-items: center; margin-bottom: 20px; }
.ourMission .text-col .icon{width: 50px;}
.ourMission .text-col p { font-size: 20px; line-height: 30px; }


.ourMission .mission-div {margin-bottom: 70px;}

@media (max-width: 1680px) {
    .ourMission .text-col .banner-title { font-size: 64px; margin-bottom: 10px; }
    .ourMission .text-col .lead{margin-bottom: 30px;font-size: 28px;}
    .ourMission .mission-div{margin-bottom: 50px;}
    .ourMission .text-col .heading{font-size: 32px;margin-bottom: 10px;}
    .ourMission .text-col .icon{width: 45px;}
}
@media (max-width: 1440px) {
    .ourMission .text-col .banner-title { font-size: 48px; }
    .ourMission .text-col .lead { font-size: 25px; margin-bottom: 20px; }
    .ourMission .text-col .text-wrapper{max-width: 750px;}
    .ourMission .mission-div{margin-bottom: 20px;}
    .ourMission .text-col .heading {gap: 10px;font-size: 28px;margin-bottom: 5px;}
    .ourMission .text-col .icon{width: 35px;}
}

@media (max-width: 1280px) {
    .ourMission .text-col .banner-title { font-size: 42px; margin-bottom: 0; }
    .ourMission .text-col .lead { font-size: 22px; }
    .ourMission .text-col p { font-size: 18px; line-height: 26px; }
}

@media (max-width: 1200px) {
    .ourMission .text-col .banner-title { font-size: 38px; }
    .ourMission .text-col .lead { font-size: 20px; margin-bottom: 10px; }
    .ourMission .mission-div { margin-bottom: 10px; }
    .ourMission .text-col .heading {font-size: 24px;}
    .ourMission .text-col .icon{width: 30px;}
}

@media (max-width: 991px) {
    .ourMission {margin: var(--section-gap) 0;}
    .ourMission .text-col,
    .ourMission .img-col{width: 100%; padding: 12px;}
    .ourMission .img-col img{max-width: 586px;border-radius: 10px;margin: auto;display: flex;}
}