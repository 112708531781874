.mainBanner { background: url('../../../public/assets/mainBanner-bg.png'); min-height: 100vh; width: 100%; display: grid; align-items: center; padding: 225px 0 185px; }
.mainBanner .banner-content-wrapper{ width: 1524px; max-width: 90vw; margin: auto; } 
.mainBanner .swiper-slide{ display: grid; align-items: center; opacity: 0 !important; }
.mainBanner .swiper-slide-active{ opacity: 1 !important; }
.mainBanner .banner-content { display: grid; align-content: center; height: calc(100% - 140px); margin: 50px 0 90px; }
.mainBanner .banner-content .banner-title { font-size: 70px; font-family: var(--heading-font); color: var(--main-color); margin-bottom: 16px; }
.mainBanner .banner-content p { font-size: 24px; line-height: 35px; color: var(--black-color); margin-bottom: 24px; max-width: 415px; }
.mainBanner img { border-radius: 15% 0; display: flex; margin-left: auto; width: 100%; max-width: 840px; object-fit: cover; aspect-ratio: 3/2; }
.mainBanner .banner-content a {text-decoration: none;}
/* .mainBanner .banner-content button,
.mainBanner .banner-content .btn { width: fit-content; border-radius: 100px; background-color: var(--main-color); border-color: var(--main-color); font-family: var(--main-font); font-size: 20px; line-height: 30px; padding: 12px 45px; text-transform: none; } */
/* .mainBanner .banner-content button:hover{background-color: var(--main-color-hover);} */

.mainBanner .swiper-button-prev, 
.mainBanner .swiper-button-next { left: unset; right: unset; transform: translate(190px, 235px); width: 50px; height: 50px; border-radius: 100%; border: 1px solid var(--main-color); background-color: #fff; opacity: 1; color: #222; }
.mainBanner .swiper-button-next { margin-left: 60px; }
.mainBanner .swiper-button-disabled { border-color: #dadada;  color: #dadada;}
.mainBanner .swiper-button-prev::after, 
.mainBanner .swiper-button-next::after { font-size: 15px; }

/* slider text animation starts here */
.mainBanner .banner-content { overflow: hidden; }
.mainBanner .swiper-slide-active .banner-content { opacity: 1; transform: translateX(0); }
.mainBanner .banner-content h2,
.mainBanner .banner-content p,
.mainBanner .banner-content button{opacity: 0;} 
.mainBanner img{opacity: 0; transition: .5s ease-in-out;} 
.mainBanner .swiper-slide-active .banner-content h2 { animation: translateIn 3s forwards; animation-delay: 0s; }
.mainBanner .swiper-slide-active .banner-content p { animation: translateIn 3s forwards; animation-delay: 1s; }
.mainBanner .swiper-slide-active .banner-content button { animation: translateIn 3s forwards; animation-delay: 1.5s; }
.mainBanner .swiper-slide-active img { opacity: 1; }

@keyframes fadeInAndOut {
    0%, 20% { opacity: 0; transform: translate3d(-100%, 0, 0); }
    25%, 75% { opacity: 1; transform: translateZ(0); }
    80%, 100% { opacity: 0; transform: translate3d(100%, 0, 0); }
}

@keyframes translateIn {
    from { opacity: 0; transform: translate3d(-100%, 0, 0); }
    to { opacity: 1; transform: translateZ(0); }
}

@keyframes translateOut {
    from { opacity: 1; transform: translateZ(0); }
    to { opacity: 0; transform: translate3d(100%, 0, 0); }
}

/* slider text animation ends here */


@media (max-width: 1680px) {
    .mainBanner {padding: 170px 0 130px;}
    .mainBanner .banner-content-wrapper{ width: 1440px; }
    .mainBanner .swiper-button-prev, 
    .mainBanner .swiper-button-next {transform: translate(120px, 230px);}
    .mainBanner .banner-content .banner-title{font-size: 64px;}
}
@media (max-width: 1536px) {
    .mainBanner .swiper-button-prev, 
    .mainBanner .swiper-button-next {transform: translate(80px, 230px);}
}
@media (max-width: 1440px) {
    .mainBanner .swiper-button-prev, 
    .mainBanner .swiper-button-next {transform: translate(70px, 230px);}
    .mainBanner .banner-content .banner-title{font-size: 52px;}
    .mainBanner .banner-content p {font-size: 22px;}
}
@media (max-width: 1366px) {
    .mainBanner {padding: 150px 0 100px;}
    .mainBanner .banner-content { height: calc(100% - 100px); margin: 30px 0 70px; }
    .mainBanner .swiper-button-prev, 
    .mainBanner .swiper-button-next {transform: translate(70px, 225px);}
}

@media (max-width: 1280px) {
    .mainBanner {min-height: 50vh;}
    .mainBanner .banner-content .banner-title{font-size: 48px;margin-bottom: 8px;}
    .mainBanner .banner-content p {font-size: 20px;margin-bottom: 15px;}
    .mainBanner .swiper-button-prev, 
    .mainBanner .swiper-button-next {transform: translate(65px, 225px);}
}

@media (max-width: 1200px) {
    .mainBanner .banner-content { height: calc(100% - 75px); margin: 25px 0 60px; }
    .mainBanner .banner-content .banner-title{font-size: 42px;margin-bottom: 8px;}
    .mainBanner .swiper-button-prev, 
    .mainBanner .swiper-button-next {transform: translate(60px, 200px);}
}

@media (max-width: 1100px) {
    .mainBanner .banner-content { height: calc(100% - 60px); margin: 20px 0 50px; }
    .mainBanner .banner-content .banner-title{font-size: 38px;}
    .mainBanner .swiper-button-prev, 
    .mainBanner .swiper-button-next {transform: translate(55px, 170px); width: 40px;height: 40px;}
    .mainBanner .swiper-button-prev::after, .mainBanner .swiper-button-next::after {font-size: 12px;}
    .mainBanner .swiper-button-next {margin-left: 50px;}
}

@media (max-width: 1024px) {
    .mainBanner .swiper-button-prev, 
    .mainBanner .swiper-button-next {transform: translate(50px, 160px);}
}

@media( max-width:991px) {
    /* .mainBanner{padding: 120px 0 80px;} */
    .mainBanner{padding: 150px 0;}
    .mainBanner .swiper-slide{display: inline;}
    .mainBanner .banner-content-wrapper {max-width: 700px;}
    .mainBanner .banner-content-wrapper .row{flex-direction: column-reverse;gap: 30px;}
    .mainBanner .banner-content { margin: 0;}
    .mainBanner .swiper-button-prev, 
    .mainBanner .swiper-button-next {top: unset; bottom: 75px;transform: translate(145px, 0);}
}
@media( max-width:767px) {
    .mainBanner .swiper-button-prev, 
    .mainBanner .swiper-button-next {transform: translate(5vw, 0);}
}
@media( max-width:600px) {
    .mainBanner{padding: 120px 0 150px;}
    .mainBanner .banner-content-wrapper {max-width: 90vw;}
}