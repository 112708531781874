.contact-us { padding: 60px 0; }

.contact-info {margin-top: 40px;}
.contact-info .heading { font-size: 24px; line-height: 38px; color: var(--main-color); margin-bottom: 0; }
.contact-info div { font-size: 20px; line-height: 30px; color: #666; }
.contact-info div:last-child {margin-bottom: 30px;}

.contact-info a {display: block; text-decoration: none; color: inherit;}
.contact-info .address,
.contact-info .email,
.contact-info .mobile { position: relative; padding-left: 40px;}
.contact-info .address::before,
.contact-info .email::before,
.contact-info .mobile::before { content: ''; position: absolute; height: 25px; width: 25px; left: 0; top: 8px; background-size: contain !important; }
.contact-info .address::before { background: url('../../../public/assets/icons/map-fill.svg') no-repeat;}
.contact-info .email::before { background: url('../../../public/assets/icons/email-fill.svg') no-repeat;}
.contact-info .mobile::before {background: url('../../../public/assets/icons/phone-fill.svg') no-repeat;}

.contact-form { border-radius: 10px; padding: 40px; box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; background-color: #fff; }
.contact-form .ant-form input,
.contact-form .ant-form textarea { font-size: 18px; line-height: 30px; padding: 12px; }
.contact-form .ant-form input:hover,
.contact-form .ant-form textarea:hover,
.contact-form .ant-form input:focus,
.contact-form .ant-form textarea:focus { border-color: var(--main-color); box-shadow: none; }

@media (max-width: 1440px) {
    .contact-info .heading { font-size: 22px; line-height: 32px; }
    .contact-info .address, 
    .contact-info .email, 
    .contact-info .mobile { padding-left: 30px; }
    .contact-info .address::before, 
    .contact-info .email::before, 
    .contact-info .mobile::before { width: 20px; height: 20px; top: 6px; }
    .contact-form{ padding: 30px;}
    .contact-form .ant-form input,
    .contact-form .ant-form textarea { font-size: 16px; line-height: 24px; padding: 7px 11px; }
}
@media (max-width: 1280px) {
    .contact-info .heading { font-size: 20px; line-height: 32px; }
    .contact-info div { font-size: 18px; line-height: 26px; }
    .contact-info .address, 
    .contact-info .email, 
    .contact-info .mobile { padding-left: 25px; }
    .contact-info .address::before, 
    .contact-info .email::before, 
    .contact-info .mobile::before { width: 17px; height: 17px; top: 5px; }
}
@media (max-width: 991px) {
    .contact-info {margin-top: 0;}
    .contact-form{ padding: 25px;}
}