.featured-vendors { margin: var(--section-gap) 0; }
/* .featured-vendors  .section-title { margin-bottom: 50px; } */
.featured-vendors .featured-list { display: flex; justify-content: center; gap: 45px; padding: 5px 70px; }

.featured-vendors .featured-list::before,
.featured-vendors .featured-list::after { content: ''; position: absolute; background-color: var(--body-bg); width: 70px; height: 100%; z-index: 2; top: 0; bottom: 0; opacity: 1; transition: .3s ease-in-out; }
/* .featured-vendors .featured-list:hover::before,
.featured-vendors .featured-list:hover::after {opacity: 1;} */

.featured-vendors .featured-list::before{ 
    left: 0; 
    /* background: linear-gradient( 270deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.3) 30%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0.9) 90%, rgba(255,255,255,1) 100%); */
}
.featured-vendors .featured-list::after { 
    right: 0; 
    /* background: linear-gradient( 90deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.3) 30%, rgba(255,255,255,0.5) 50%, rgba(255,255,255,0.9) 90%, rgba(255,255,255,1) 100%);  */
}

/* .featured-vendors .swiper-wrapper {width: calc(100% - 125px); overflow: hidden;margin: auto;} */

.featured-vendors .swiper-button-prev, 
.featured-vendors .swiper-button-next { top: 50%; transform: translateY(-50%); width: 50px; height: 50px; border-radius: 100%; border: 1px solid var(--main-color); background-color: #fff; opacity: 1; color: #222; margin: 0; opacity: 0; transition: .2s ease-in-out;}
.featured-vendors .featured-list:hover .swiper-button-prev, 
.featured-vendors .featured-list:hover .swiper-button-next {opacity: 1;} 
.featured-vendors .swiper-button-prev::after, 
.featured-vendors .swiper-button-next::after { font-size: 15px; }
.featured-vendors .swiper-button-disabled { border-color: #dadada;  color: #dadada; }
.featured-vendors .featured-list img {filter: grayscale(1); transition: .2s ease-in-out;display: flex;margin: auto;height: 100px;}
.featured-vendors .featured-list img:hover {filter: grayscale(0)}


@media (max-width: 1100px) {
    .featured-vendors .swiper-button-prev, 
    .featured-vendors .swiper-button-next { width: 40px;height: 40px; }
    .featured-vendors .swiper-button-prev::after, .mainBanner .swiper-button-next::after { font-size: 12px; }
}