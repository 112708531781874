.privacy-policy { padding: 60px 0; min-height: 37vh; }
.privacy-policy .heading { color: var(--black-color); font-size: 25px; line-height: 25px; margin-bottom: 10px; text-transform: capitalize; }
.privacy-policy .border { border-bottom: 2px solid var(--main-color) !important; flex-shrink: 0; height: 2px; margin-bottom: 15px; opacity: 0.8; width: 73.466px; }
.privacy-policy .desc { color: #333; font-size: 16px; font-style: normal; font-weight: 400; line-height: 28px; margin-bottom: 35px;  white-space: pre-wrap;}
.privacy-policy .desc p:last-child { margin-bottom: 0; }

@media (max-width: 1536px) {
  .privacy-policy .heading { font-size: 20px; margin-bottom: 10px; }
  .privacy-policy .desc { font-size: 15px; line-height: 24px; margin-bottom: 25px;}
}

@media (max-width: 1440px) {
  .privacy-policy .heading { margin-bottom: 5px; }
}
@media (max-width: 1200px) {
  .privacy-policy .desc { margin-bottom: 20px; }
}
@media (max-width: 600px) {
  .privacy-policy .desc { font-size: 14px; line-height: 22px; }
}
