.faqs { margin: var(--section-gap) 0; }
.faqs .accordion-item { border: none; border-bottom: 1px solid #DBDBDB; background-color: transparent; margin-bottom: 0; }
.faqs .accordion-item .accordion-button { font-size: 24px; line-height: 38px; font-weight: 500; background-color: transparent; padding: 25px 0; box-shadow: none; border-color: rgba(0,0,0,.125); transition: all .2s ease-in-out; }
.faqs .accordion-item.active .accordion-button { background-color: transparent; padding-bottom: 10px; color: inherit; }
.faqs .accordion-item .accordion-body { padding: 0 0 25px; padding-top: 0; font-size: 20px; line-height: 30px; border: none !important; }
.faqs .accordion-button::after { content: none; }
.faqs .accordion-button span { margin-left: auto; font-size: 24px; }

@media (max-width: 1440px) {
    .faqs .accordion-item .accordion-button { font-size: 22px; line-height: 36px; padding: 20px 0; }
    .faqs .accordion-item .accordion-body { padding-bottom: 20px; }
}