.howItWorks { background: #E8F0F3 url('../../../public/assets/how-bg.png') center no-repeat; background-size: cover; padding: var(--section-gap) 0; min-height: 100vh; }
/* .howItWorks .section-title { margin-bottom: 50px; } */
.howItWorks .nav-pills { background-color: #E8F0F3; width: fit-content; margin: auto; box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; border-radius: 100px; }
.howItWorks .nav-pills .nav-link { color: var(--light-grey-color); border-radius: 100px; padding: 12px 53px; font-size: 20px; line-height: 30px; }
.howItWorks .nav-pills .nav-link.active { background-color: var(--main-color); color: var(--white-color); }
.howItWorks .lead { font-size: 24px; line-height: 38px; margin-bottom: 50px; color: var(--black-color); font-weight: 400; }
.howItWorks .points { list-style: decimal-leading-zero; }
.howItWorks .points .heading { font-size: 24px; line-height: 38px; margin-bottom: 10px; color: var(--black-color); }
.howItWorks .points p { font-size: 18px; line-height: 28px; }
.howItWorks .points li::marker{ font-family: var(--heading-font); font-size: 40px; margin-bottom: -20px; color: #547a8726; font-weight: 600; }
.howItWorks .media-col {height: 100%;display: flex;align-items: center;}
.howItWorks img { margin-left: auto; }


@media (max-width: 1440px) {
    .howItWorks .nav-pills .nav-link {min-width: 150px;padding: 8px;font-size: 18px;}
    .howItWorks .points li::marker{ font-size: 32px; }
    .howItWorks .lead { font-size: 22px; line-height: 32px; margin-bottom: 30px; }
}
@media (max-width: 991px) {
    .howItWorks .points { margin-bottom: 20px; }
    .howItWorks .points li::marker{ font-size: 28px; }
}
@media (max-width: 600px) {
    .howItWorks .lead { font-size: 20px; line-height: 28px; margin-bottom: 25px; }
    .howItWorks .nav-pills .nav-link { min-width: 100px; padding: 8px 25px; font-size: 16px; line-height: 24px; }
    .howItWorks .points .heading { font-size: 22px; line-height: 32px; margin-bottom: 5px; }
    .howItWorks .points li::marker{ font-size: 18px; }
    .howItWorks .points p { font-size: 16px; line-height: 24px; }
}
