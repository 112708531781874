/* .header { background: url('../../../public/assets/header-bg.png') top center no-repeat; background-size: contain; position: fixed; top: 0; width: 100%; transition: all .2s ease-in-out; } */
.header { position: fixed; top: 0; width: 100%; transition: all .2s ease-in-out; z-index: 10; }
.header .header-top{ height: 60px; width: 100%; background: url('../../../public/assets/header-bg.png') top center no-repeat; background-size: cover; position: absolute; z-index: 9; transition: all .2s ease-in-out; }
.header .logo { background-color: #F8FCFE; width: 190px; height: 190px; display: grid; place-items: center; border-radius: 100%; }
.header .logo img { width: 140px; max-width: 100%; }
.header .navbar { z-index: 10;}
.header .navbar-nav { margin-left: 50px; }
.header .button-group {display: flex;flex-wrap: wrap;gap: 15px;}
.header .user-icon { position: relative; background-color: transparent; border: 1px solid #D1D1D1; width: 54px; height: 54px; display: grid; place-items: center; border-radius: 100%; }
.header .user-icon img {width: 18px;height: 22px; }
.header .nav-link { font-size: 20px; line-height: 30px; padding: 8px 20px !important; }
.header .menu-toggler {display: none;border: none;background-color: transparent;position: relative;}

.header .navbar-toggler-icon { width: 1.5rem; height: 1.5rem; }
.header.stickyHeader { background-image: none; box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; backdrop-filter: blur(16px) saturate(180%); -webkit-backdrop-filter: blur(16px) saturate(180%); background-color: rgba(255, 255, 255, 0.75); }

.header.stickyHeader .header-top { display: none; } 
.header.stickyHeader .logo { height: 100px; width: 100px; background-color: transparent; }
.header.stickyHeader .logo img{ width: 90%; }

@media (max-width: 1680px) {
    .header .header-top {height: 50px;}
    .header .logo {width: 175px;height: 175px;}
    .header .logo img{width: 125px;}
}
@media (max-width: 1440px) {
    .header .user-icon { height: 46px; width: 46px; }
    .header .user-icon img { width: 16px; }
}
@media (max-width: 1366px) {
    .header .header-top {height: 40px;}
    .header .logo{width: 140px;height: 140px;}
    .header .logo img{ width: 100px;}
    .header.stickyHeader .logo { height: 85px; width: 85px; }
    .header .navbar-nav { margin-left: 25px; }
    .header .nav-link {font-size: 18px;padding: 8px 15px !important;}
}
@media (max-width: 991px) {
    .header .menu-toggler {display: flex}
    .header .header-top {height: 30px;}
    .header .offcanvas,
    .header.stickyHeader .offcanvas{height: 100vh;max-width: 85vw;}
    .header .logo{width: 120px; height: 120px;}
    .header .logo img{width: 85px;}
    .header.stickyHeader .logo { height: 75px; width: 75px; }
    .header .navbar-nav { align-items: start !important; margin-left: 0; }
    .header .nav-link { padding-inline: 0 !important; }
    .header .button-group {margin-top: 8px; gap: 8px;}
}
@media (max-width: 600px) {
    .header .header-top {height: 25px;}
    .header .logo{width: 100px; height: 100px;}
    .header .logo img{width: 75px;}
}