.footer { background: url('../../../public/assets/footer-bg.png') center no-repeat; background-size: cover; }
.footer .footer-main { padding: 75px 0 80px; }
.footer .logo { background-color: #F3F8FB; width: 240px; height: 240px; display: grid; place-items: center; border-radius: 100%; margin-bottom: 16px; }
.footer .logo img{ width: 172px;}
.footer .social-links { display: flex; flex-wrap: wrap; gap: 30px; }
.footer .footer-links .heading { font-size: 24px; line-height: 38px; margin-bottom: 20px; color: var(--black-color); }
.footer .footer-links ul { list-style: none; padding-left: 0; }
.footer .footer-links li { font-size: 20px; line-height: 30px; margin-bottom: 10px; }
.footer .footer-links li a{ text-decoration: none; color: inherit; }
.footer .footer-links li a:hover { color: var(--main-color); }
.footer .footer-links .address,
.footer .footer-links .email,
.footer .footer-links .mobile { position: relative; padding-left: 30px;}
.footer .footer-links .address::before,
.footer .footer-links .email::before,
.footer .footer-links .mobile::before { content: ''; position: absolute; height: 25px; width: 25px; left: 0; top: 8px; }
.footer .footer-links .address::before { background: url('../../../public/assets/icons/map.svg') no-repeat;}
.footer .footer-links .email::before { background: url('../../../public/assets/icons/email.svg') no-repeat;}
.footer .footer-links .mobile::before {background: url('../../../public/assets/icons/phone.svg') no-repeat;}

.footer .footer-bottom { border-top: 1px solid #DFDFDF; padding: 20px 0; text-align: center; font-size: 18px; line-height: 30px; }
.footer .footer-bottom .website{ color: var(--black); font-weight: 500; }

@media (max-width: 1680px) {
    .footer .logo {width: 215px;height: 215px;}
    .footer .logo img{ width: 160px;}
}
@media (max-width: 1440px) {
    .footer .logo {width: 185px;height: 185px;}
    .footer .logo img{ width: 140px;}
    .footer .footer-links .heading{margin-bottom: 15px;}
    .footer .footer-links li { font-size: 18px; line-height: 25px; }
    .footer .footer-links .address::before, .footer .footer-links .email::before, .footer .footer-links .mobile::before{top: 5px;}
    .footer .social-links{gap: 25px;}
}
@media (max-width: 1440px) {
    .footer .brand-info { margin-bottom: 20px; }
    .footer .footer-main {padding: 50px 0;}
    .footer .footer-bottom{padding: 15px;}
}