.pageHeader {height: 380px;display: grid;place-content: center;text-align: center;background: url('../../../public/assets/mainBanner-bg.png') center no-repeat;}
.pageHeader .page-title {font-family: var(--heading-font);color: var(--main-color);font-size: 50px;margin-bottom: 20px;padding-top: 60px;}
.pageHeader .breadCrumbs {font-size: 20px;line-height: 30px;}
.pageHeader .breadCrumbs a {text-decoration: none; color: inherit}
.pageHeader .breadCrumbs a:hover {color: var(--main-color);}

@media (max-width: 1440px) {
    .pageHeader {height: 300px;}
    .pageHeader .page-title {font-size: 42px;margin-bottom: 5px;}
    .pageHeader .breadCrumbs {font-size: 18px;}
}
@media (max-width: 1280px) {
    .pageHeader {height: 250px;}
}