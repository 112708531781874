.terms-condition { padding: 60px 0; min-height: 37vh; }
.terms-condition .heading { color: var(--black-color); font-size: 25px; line-height: 25px; margin-bottom: 10px; text-transform: capitalize; }
.terms-condition .border { border-bottom: 2px solid var(--main-color) !important; flex-shrink: 0; height: 2px; margin-bottom: 15px; opacity: 0.8; width: 73.466px; }
.terms-condition .desc { color: #333; font-size: 16px; font-style: normal; font-weight: 400; line-height: 28px; margin-bottom: 35px;white-space: pre-wrap;}
.terms-condition .desc p:last-child { margin-bottom: 0; }

@media (max-width: 1536px) {
  .terms-condition .heading { font-size: 20px; margin-bottom: 10px; }
  .terms-condition .desc { font-size: 15px; line-height: 24px; margin-bottom: 25px; }
}

@media (max-width: 1440px) {
  .terms-condition .heading { margin-bottom: 5px; }
}
@media (max-width: 1200px) {
  .terms-condition .desc { margin-bottom: 20px; }
}
@media (max-width: 600px) {
  .terms-condition .desc { font-size: 14px; line-height: 22px; }
}
