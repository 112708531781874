.ourStory {background: url('../../../public/assets/how-bg.png') no-repeat; background-size: cover;padding: var(--section-gap) 0;min-height: 78vh;}
.ourStory .text-col .banner-title {font-size: 70px; font-weight: 500;; font-family: var(--heading-font); color: var(--main-color);margin-bottom: 20px;}
.ourStory .text-col .lead { font-size: 30px; font-weight: 400; line-height: 38px;color: var(--black-color); margin-bottom: 20px; }
.ourStory .text-col p { font-size: 20px; line-height: 30px; }

.ourStory .stats { display: flex; flex-wrap: wrap; gap: 24px; }
.ourStory .stats-row { flex: 0 0 calc(50% - 12px); }
.ourStory .stats-row-2 { margin-top: 60px; }
.ourStory .stats .stats-card{ padding: 30px; background-color: #fff; border-radius: 10px; margin-bottom: 24px; box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; }
.ourStory .stats .stats-card .number { font-size: 60px; color: var(--main-color); font-weight: 600; margin-bottom: 5px;line-height: 60px;}
.ourStory .stats .stats-card .text {font-size: 20px;line-height: 30px;}
.ourStory .stats .stats-card .text {font-size: 20px;line-height: 30px;}

@media (max-width: 1680px) {
    .ourStory .text-col .banner-title { font-size: 64px; margin-bottom: 10px; }
    .ourStory .text-col .lead { font-size: 28px; }
    .ourStory .stats .stats-card {padding: 25px;}
    .ourStory .stats .stats-card .number{font-size: 52px;}
}

@media (max-width: 1440px) {
    .ourStory .text-col .banner-title { font-size: 48px; }
    .ourStory .text-col .lead { font-size: 25px; line-height: 32px; }
    .ourStory .stats {gap: 20px;}
    .ourStory .stats .stats-card {padding: 20px; margin-bottom: 20px;}
    .ourStory .stats .stats-card .number{font-size: 48px;line-height: 52px;}
    .ourStory .stats .stats-card .text {font-size: 18px;line-height: 24px;}
}
@media (max-width: 1280px) {
    .ourStory .text-col .banner-title { font-size: 42px; margin-bottom: 0; }
    .ourStory .text-col .lead { font-size: 22px; line-height: 28px; }
}
@media (max-width: 1200px) {
    .ourStory .text-col .banner-title { font-size: 38px; }
    .ourStory .text-col .lead { font-size: 20px; line-height: 24px; }
    .ourStory .stats {gap: 15px;}
    .ourStory .stats .stats-card { padding: 15px; margin-bottom: 15px; }
    .ourStory .stats .stats-card .number{font-size: 36px;line-height: 40px;}
    .ourStory .stats .stats-card .text {font-size: 16px;line-height: 22px;}
}